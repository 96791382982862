import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";
import { eventEmitter } from "framework/src/utils/EventEmitter";


interface ILoginPasswordResponse {
  meta: {
    message: string;
    token: string;
    account: {
      data: {
        id: string;
        type: string;
        attributes: {
          first_name: string;
          last_name: string;
          full_phone_number: string;
          country_code: null;
          phone_number: string;
          email: string;
          activated: boolean;
          account_type: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
        }
        }
      }
    }
  }
}

interface ApiCallInterface {
  contentType: string;
  method: string;
  endPoint: string;
  body?: Object;
  type?: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleSignUp:() => void
  handleLogin: (event:React.MouseEvent<HTMLDivElement>) => void;
  handleMagicLink: (email: string) => void;
  isOpen: boolean;
  isMobileScreen: boolean;
  onLoginClose: () => void;
  screenType : 'login' | 'profile' | "magic";
  onLoginSuccess: (token: string) => void;
  goToHomePage: () => void;
  showProfile: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  enablePasswordField_2: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  emailValue:string;
  emailError:boolean;
  emailErrorMessage:string;
  showPassField:boolean;
  passwordShow:boolean;
  passwordValue:string;
  showErroPopup:boolean;
  isLoaderOpen: boolean;
  showErrorMessage: string;
  activeButton: "login" | "email"
  showForgotPassword: boolean;
  pwResetResendLink: boolean;
  setNewPwScreen: boolean;
  newPassword: string;
  newPasswordError: boolean;
  newPasswordErrorMessage: string;
  confirmPassword: string;
  confirmPasswordError: boolean;
  confirmPasswordErrorMessage: string;
  pwResetSuccess: boolean;
  loginToken: string;
  isLogoutModalOpen: boolean,
  isDeleteModalOpen: boolean,
  magicLinkLoader: boolean,
  metaToken: string;
  countValue: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  passwordVerificationId: string = "";
  magicLinkVerificationId: string = "";
  verifyEmailId: string = "";
  forgotPasswordId: string = "";
  resetPasswordId: string = "";
  deleteAccountApiId: string = "";
  getCartIdApiCallId: string = "";
  getCartItemCountCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: false,
      enablePasswordField_2: false,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      emailValue:"",
      emailError:false,
      emailErrorMessage:"",
      showPassField:false,
      passwordShow:false,
      passwordValue:"",
      showErroPopup:false,
      isLoaderOpen: false,
      showErrorMessage: "",
      activeButton: "login",
      showForgotPassword: false,
      pwResetResendLink: false,
      setNewPwScreen: false,
      newPassword: "",
      newPasswordError: false,
      newPasswordErrorMessage: "",
      confirmPassword: "",
      confirmPasswordError: false,
      confirmPasswordErrorMessage: "",
      pwResetSuccess: false,
      loginToken: "",
      isLogoutModalOpen: false,
      isDeleteModalOpen: false,
      magicLinkLoader: false,
      metaToken: "",
      countValue:0
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    eventEmitter.subscribe("forgotPwEmailClicked", async () => {
      setStorageData("priorScreen", "forgotPw");
      const loginToken = await getStorageData('loginToken');
      this.setState({
        loginToken,
        showForgotPassword: true,
        setNewPwScreen: true,
      })
    });
    localStorage.removeItem('profileModal')
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = (forSecond: boolean = false) => {
    if (forSecond) {
      this.setState((prevState) => ({ enablePasswordField_2: !prevState.enablePasswordField_2 }));
    } else {
      this.setState((prevState) => ({ enablePasswordField: !prevState.enablePasswordField }));
    }
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

    handleEmailChange = (event:React.ChangeEvent<HTMLInputElement>) =>{
      this.setState({
        emailValue:event.target.value.trim(),
        emailError:false,
        emailErrorMessage:"",
        showErroPopup:false,
        showErrorMessage: ""
      })
    } 
    handleNext = () => {
      if(this.state.emailValue === ""){
        this.setState({
          emailError:true,
          emailErrorMessage:'This filed is required'
        })
        return;
      }

      if(!configJSON.emailValidation.test(this.state.emailValue)){
          this.setState({
            emailError:true,
            emailErrorMessage:'Invalid email'
          })
          return;
        }
        this.verifyEmail();
    }

    handlePassword = (event:React.ChangeEvent<HTMLInputElement>) =>{
      this.setState({passwordValue:event.target.value,emailError:false})
    }
    handleBackToLogin = () => {
      this.setState({showPassField:false, showForgotPassword: false, emailError: false, emailErrorMessage: ""})
    }
  handleLogin = () => {
    if (this.state.passwordValue === "") {
      this.setState({
        emailError: true,
        emailErrorMessage: configJSON.requiredField
      })
    } else {
      const bodyData = {
        data: {
          attributes: {
            email: this.state.emailValue,
            password: this.state.passwordValue,
          },
          type: "email_account"
        }
      }
      const requestMessage = this.generateRunEngineRequestMessage(configJSON.loginPasswordEndpoint, configJSON.postAPIMethod)
      this.passwordVerificationId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      this.setState({activeButton: "login", isLoaderOpen: true})
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  generateRunEngineRequestMessage = (endPoint: string, method: string) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    return requestMessage;
  }

  handlePasswordVerification = async (message: Message) => {
    const {  apiSuccessMessageData } = this.getResponseMessage(message); 
    const response = apiSuccessMessageData as ILoginPasswordResponse
    if ((response.meta) && (response.meta.message === "Login successful")) {
      setStorageData(
        "loginToken",
        response.meta.token
      );
      const currentPath = window.location.pathname;
      const pathParts = currentPath.split('/');
      if( pathParts[1] === 'payment-success'){
        window.location.href = '/'
      }
      
      eventEmitter.dispatch("loginSuccess", response.meta.token);
      const cartId = await localStorage.getItem('cartId');
      if (cartId) {
        await this.handleGetCartId(cartId);
        this.setState({ showErroPopup: false, showErrorMessage: "", metaToken: response.meta.token });
      }
    } else {
      this.setState({emailError: true, emailErrorMessage: configJSON.enterValidPassword} )
    }

  }

  handleSetNewPassword = async () => {
    if (this.state.newPassword === "") {
      this.setState({
        newPasswordError: true,
        newPasswordErrorMessage: configJSON.requiredField
      })
    } else if (this.state.confirmPassword === "") {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordErrorMessage: configJSON.requiredField
      })
    } else if (this.state.newPassword.length < 6) { 
      this.setState({
        newPasswordError: true,
        newPasswordErrorMessage: configJSON.passwordLengthError
      })
    } else if (this.state.confirmPassword.length < 6) {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordErrorMessage: configJSON.passwordLengthError
      })
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordErrorMessage: configJSON.passwordMismatch
      })
    } else {
      const bodyData = {
        data: {
          token: this.state.loginToken,
          new_password: this.state.newPassword,
          password_confirmation: this.state.confirmPassword
        }
      }
      const requestMessage = this.generateRunEngineRequestMessage(configJSON.resetPasswordEndpoint, configJSON.postAPIMethod)
      this.resetPasswordId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      this.setState({isLoaderOpen: true})
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleSendRecoveryLink = () => {
    const bodyData = {
      data: {
        email: this.state.emailValue
      }
    }

    const requestMessage = this.generateRunEngineRequestMessage(configJSON.forgotPasswordEndpoint, configJSON.postAPIMethod)
    this.forgotPasswordId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    this.setState({isLoaderOpen: true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleForgotPwBtnClick = () => {
    this.setState({showForgotPassword: true})
  }

  handleNewPassword = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({newPassword:event.target.value, newPasswordError:false})
  }

  handleConfirmPassword = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({confirmPassword:event.target.value, confirmPasswordError:false})
  }
  

  handleLogOut = () => {
    removeStorageData("loginToken");
    removeStorageData("mobileUserId");
    this.props.goToHomePage();
  }

  handleProfileClick = () => {
    window.location.href = "/profile";
  }

  handleManageAddress = () => {
    window.location.href = "/profile/address-management";
  }

  handleOrderHistory = () => {
    window.location.href = "/profile/orders";
  }

  handleResponseMessage = (message: Message) => {
    const { apiResponseData, apiErrorMessageData } = this.getResponseMessage(message);

    try {
      this.setState({isLoaderOpen: false});
      if (!apiErrorMessageData) {
        (apiResponseData === this.passwordVerificationId) && (this.handlePasswordVerification(message));
        (apiResponseData === this.verifyEmailId) && (this.handleVerifyEmailMsg(message));
        (apiResponseData === this.magicLinkVerificationId) && (this.handleMagicLink(message));
        (apiResponseData === this.forgotPasswordId) && (this.handleForgotPassword());
        (apiResponseData === this.resetPasswordId) && (this.setState({pwResetSuccess: true}));
        (apiResponseData === this.getCartIdApiCallId) && (this.cartIdApiSuccessResponse(message));
        (apiResponseData === this.getCartItemCountCallId) && (this.handleCartItemCountRespense(message));
      }
    }
    catch (error) {
      this.setState({isLoaderOpen: false});
    }

  }

  handleForgotPassword = () => {
    this.setState({
      pwResetResendLink: true,
    })
  }

  handleVerifyEmailMsg = (message: Message) => {
    const { apiSuccessMessageData } = this.getResponseMessage(message);

    if (apiSuccessMessageData.message === "Account activated") {
      this.setState({ showPassField: true, showErroPopup: false })
    } else {
      this.setState({
        showErroPopup: true
      })
    }
  }

  verifyEmail = () => {
    const verifyEmailMessage = this.generateRunEngineRequestMessage(
      `${configJSON.checkMailEndPoint}?[data][attributes][email]=${this.state.emailValue}`,
      configJSON.getAPIMethod);
    this.verifyEmailId = verifyEmailMessage.messageId;
    this.setState( {isLoaderOpen: true} );
    runEngine.sendMessage(verifyEmailMessage.id, verifyEmailMessage);
  }

  handleMagicLink = (message: Message) => {
    const { apiSuccessMessageData } = this.getResponseMessage(message);
    if (apiSuccessMessageData.message) {
      this.props.handleMagicLink(this.state.emailValue)
    } else {
      this.setState({
        showErroPopup: true,
        showErrorMessage: configJSON.magicLinkError
      })
    }
  }

  loginDirectlyThroughEmail = () => {
    const bodyData = {
      data: {
        attributes: {
          email: this.state.emailValue,
        },
      }
    }

    const magicLinkMessage = this.generateRunEngineRequestMessage(configJSON.magicLinkEndpoint, configJSON.postAPIMethod)
    this.magicLinkVerificationId = magicLinkMessage.messageId;

    magicLinkMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    this.setState( {magicLinkLoader: true, activeButton: "email"} );
    runEngine.sendMessage(magicLinkMessage.id, magicLinkMessage);
  }

  

    getResponseMessage = (message:Message) => {
      const apiResponseData = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const apiSuccessMessageData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      const apiErrorMessageData = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      return ({ apiResponseData, apiSuccessMessageData, apiErrorMessageData });
    }

    handleDeleteModalOpen = () => {
      this.setState({
        isDeleteModalOpen: true
      });
    };

    handleDeleteModalClose = () => {
      this.setState({
        isDeleteModalOpen: false
      });
    };

    handleLogoutModalOpen = () => {
      this.setState({
        isLogoutModalOpen: true
      });
    };

    handleLogoutModalClose = () => {
      this.setState({
        isLogoutModalOpen: false
      });
    };

    deleteUser = async ()=> {
      const contactMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const loginToken = await getStorageData('loginToken');
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: loginToken,
      };
      const mobileUserId = await getStorageData('mobileUserId');
      const endPoint = `${configJSON.apiEndPointDeleteUserWeb}/${mobileUserId}/delete_account`;
  
      this.deleteAccountApiId = contactMessage.messageId;
      contactMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiDeleteUser
      );
      contactMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      contactMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      runEngine.sendMessage(contactMessage.id, contactMessage);
      }

  handleGetCartId = async (cartId: string) => {
    this.getCartIdApiCallId = await this.cartIdApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getCartIdAPiEndPoint}${cartId}`
    });
  };

  handleGetCartItems = async (cartId: string) => {
    this.getCartItemCountCallId = await this.cartIdApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.cartItemCountEndpoint}/${cartId}`
    });
  };

  handleCartItemCountRespense = (message: Message) => {
    const { apiSuccessMessageData } = this.getResponseMessage(message);
    const response = apiSuccessMessageData
    if (response?.data) {
      let count = response.data.attributes.cart_items_count;
      this.setState({ countValue: count }, () => {
        eventEmitter.dispatch("badgeValue", this.state.countValue);
      });
    }
    this.props.onLoginSuccess(this.state.metaToken);
  };

  cartIdApiCall = async (apiData: ApiCallInterface) => {
    const token  = await getStorageData("loginToken");
    const { contentType, method, endPoint, body } = apiData;
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  cartIdApiSuccessResponse = async (message: Message) => {
    const { apiSuccessMessageData } = this.getResponseMessage(message);
    const response = apiSuccessMessageData
    await setStorageData('cartId', response.data.id);
    await setStorageData('cartUUID', response.data.attributes.uuid);
    await this.handleGetCartItems(response.data.attributes.uuid)
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson?.meta?.account?.data?.id){
        setStorageData(
          "mobileUserId",
          responseJson?.meta?.account?.data?.id
        );
      } else if(apiRequestCallId === this.deleteAccountApiId){
        this.handleLogOut()
      }

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            this.openInfoPage();
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            this.sendLoginFailMessage();
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }

        this.handleResponseMessage(message);
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
