import { Message } from '../../../../framework/src/Message';
import MessageEnum, { getName } from '../Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import { getResponseVariables } from '../GetResponseVariables/GetResponseVariables';
import {
  getStorageData,
  removeStorageData,
} from '../../../../framework/src/Utilities';

export const isTokenExpired = (message: Message) => {
  const [responseJson] = getResponseVariables(message);
  if (responseJson?.errors) {
    if (
      Array.isArray(responseJson?.errors) &&
      responseJson?.errors.length > 0 &&
      responseJson?.errors[0]?.token &&
      (responseJson?.errors[0]?.token == 'Token has Expired' ||
        responseJson?.errors[0]?.token == 'Invalid token' ||
        responseJson?.errors[0]?.token == 'invalid token')
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export const parseExpireTokenResponse = (
  responseJson: any,
  state: any,
  props: any
) => {
  if (responseJson?.errors) {
    if (!state.invalidTokenMessageRecieved) {
      if (
        Array.isArray(responseJson?.errors) &&
        responseJson?.errors.length > 0 &&
        responseJson?.errors[0]?.token &&
        (responseJson?.errors[0]?.token == 'Token has Expired' ||
          responseJson?.errors[0]?.token == 'Invalid token' ||
          responseJson?.errors[0]?.token == 'invalid token')
      ) {
        return false;
      }
      return true;
    }
  }
  return true;
};

export const clearStorageData = async () => {
  removeStorageData('admintoken');
  removeStorageData('adminuser');
};

export const logoutAndRedirectToLoginPage = (props: any) => {
  clearStorageData();
  navigateToLogin(props);
};

export const checkResponseError = (responseJson: any) => {
  return !responseJson || responseJson?.errors || responseJson?.error;
};

export const getError = (responseJson: any) => {
  const errors =
    responseJson?.error ||
    responseJson?.errors ||
    'An error occurred during connection to the server';
  const isOnlyOneString = typeof errors === 'string';
  const stringErrors = typeof errors[0] === 'string';
  return isOnlyOneString
    ? errors
    : stringErrors
    ? errors.join('\n')
    : errors
        .map((i: any) => Object.values(i))
        .flat()
        .join('\n');
};

export const showError = (
  title: any,
  responseJson: any,
  hideLoader: any,
  setDialogState: any
) => {
  hideLoader?.();
  const errorMessage = getError(responseJson);
  setDialogState?.(true, {
    title: title,
    message: errorMessage,
    confirmColor: 'white',
    confirmBackground: '#FF1744',
    confirmHoverBackground: 'rgb(240, 25, 73)',
    hideCancel: true,
  });
  return errorMessage;
};

export const generateRequestMessage = async (
  endpoint: any,
  type: any,
  additionalHeaders?: any,
  isLoadingRequired?: () => void,
  isLoading?: boolean
) => {
  isLoading && isLoadingRequired && isLoadingRequired();
  const headers = {
    'content-type': 'application/json',
    token: await getStorageData('admintoken'),
    ...(additionalHeaders || {}),
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const addSlash = !endpoint.startsWith('/') && !endpoint.startsWith('http');
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    addSlash ? `/${endpoint}` : endpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    type
  );
  return requestMessage;
};

export const showResponseDialog = (
  title: any,
  responseJson: any,
  hideLoader: any,
  setDialogState: any
) => {
  hideLoader?.();
  let responseMessage = '';
  if (responseJson.success && responseJson.error) {
    responseMessage = `${responseJson.success}\n${responseJson.error}`;
  } else if (responseJson.success) {
    responseMessage = responseJson.success;
  } else if (responseJson.error) {
    responseMessage = responseJson.error;
  } else {
    responseMessage = 'No message received.';
  }

  setDialogState?.(true, {
    title: title,
    message: responseMessage,
    confirmColor: 'white',
    confirmBackground: '#FF1744',
    confirmHoverBackground: 'rgb(240, 25, 73)',
    hideCancel: true,
  });
  return responseMessage;
};

export const navigateToLogin = (props: unknown) => {
  const to = new Message(getName(MessageEnum.NavigationMessage));
  to.addData(
    getName(MessageEnum.NavigationTargetMessage),
    'admin/EmailAccountLogin'
  );
  to.addData(getName(MessageEnum.NavigationPropsMessage), props);
  runEngine.sendMessage(to.messageId, to);
};

export const decimalConversion = (value: string | number | null) => {
  if (value === '' || value === null) {
    return '';
  }
  const stringValue = typeof value === 'number' ? value.toString() : value;
  const matches = stringValue.match(/(\D*)(\d+\.?\d*)/);
  if (!matches) {
    return '';
  }
  const currencySymbol = matches[1];
  const numericString = matches[2];
  const formattedNumericString = parseFloat(numericString).toFixed(2);
  const formattedWithCommas = new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(formattedNumericString));
  return `${currencySymbol}${formattedWithCommas}`;
};

export const getBannerURL = (bannerURL = '', bannerVariants: any = {}) => {
  const width = window.innerWidth;

  if (Object.keys(bannerVariants).length > 0) {
    if (width <= 360) {
      return bannerVariants['360'] || bannerURL;
    } else if (width <= 414) {
      return bannerVariants['414'] || bannerURL;
    } else if (width <= 601) {
      return bannerVariants['601'] || bannerURL;
    } else if (width <= 768) {
      return bannerVariants['768'] || bannerURL;
    } else if (width <= 1280) {
      return bannerVariants['1280'] || bannerURL;
    } else if (width <= 1920) {
      return bannerVariants['1920'] || bannerURL;
    } else {
      return bannerURL;
    }
  }
  return bannerURL;
};
